<!--
  Requisitos para a utilizar este componente:
    Props:
      - (Boolean) limitOfOptionsOn: Está props define se existirá limite de opções ou não;
-->

<template>
  <div class="d-flex flex-column justify-space-between">
    <v-row>
      <v-col v-if="limitOfOptionsOn" cols="12" sm="6">
        <!-- LIMITE DE OPÇÕES -->
        <v-select
          class="white"
          ref="limite_de_opcoes"
          label="Limite de Opções"
          messages="Escolha o limite de opções que poderão ser assinalados. - <strong>Opcional</strong>"
          placeholder="Selecione o limite de opções"
          :items="limitOptions"
          item-text="text"
          item-value="value"
          dense
          outlined
          v-model="pergunta.limite_de_opcoes"
        >
          <template slot="message" slot-scope="data">
            <span v-html="data.message"></span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <!-- BOTÃO DE ADICIONAR OPÇÃO -->
        <v-btn
          dark
          color="primary"
          :disabled="processOfSave"
          @click="addItem()"
        >
          <v-icon dark> mdi-plus </v-icon>
          <span>Adicione uma Opção</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="my-3 grey" />

    <v-card
      v-if="pergunta.opcoes.length > 0"
      width="100%"
      elevation="0"
      class="pa-1"
    >
      <draggable
        v-bind="dragOptions"
        :list="pergunta.opcoes"
        handle="#handle"
        class="width-max-width-custom"
      >
        <v-card
          elevation="2"
          outlined
          loader-height="7"
          v-for="(opcao, index) in pergunta.opcoes"
          :key="opcao.id"
          class="mx-3 my-3 d-flex flex-row justify-space-between align-center"
          min-height="3em"
        >
          <div
            class="h-full d-flex flex-row justify-space-between align-center"
            style="width: 100% !important"
            v-if="
              opcao.descricao != null ||
              opcao.descricao != undefined ||
              opcao.descricao != ''
            "
          >
            <!-- BOTÃO DRAGDROP -->
            <v-btn
              :disabled="processOfSave"
              id="handle"
              min-width="15px"
              elevation="0"
              class="grabbable h-full px-0 py-6 white"
            >
              <v-icon>mdi-drag-vertical</v-icon>
            </v-btn>
            <!-- POSIÇÃO -->
            <div class="px-2">
              <h4 class="text-center">
                {{ (opcao.posicao = index + 1) }}
              </h4>
            </div>

            <v-card elevation="0" class="px-2" style="width: 100% !important">
              <!-- DESCRIÇÃO -->
              <v-text-field
                :ref="`${defineScope}.${opcao.posicao}`"
                label="Opção"
                placeholder="Descreva a opção"
                required
                outlined
                dense
                class="white"
                hide-details
                v-model.trim="opcao.descricao"
                :data-vv-as="`${defineScope}.${opcao.posicao}`"
                persistent-hint
                :hint="errors.first(`${defineScope}.${opcao.posicao}`)"
                :error="
                  errors.collect(`${defineScope}.${opcao.posicao}`).length
                    ? true
                    : false
                "
                :data-vv-scope="defineScope"
                v-validate="{ required: true }"
                data-vv-validate-on="blur|change"
                :id="`${defineScope}.${opcao.posicao}`"
                :name="`${defineScope}.${opcao.posicao}`"
              ></v-text-field>
            </v-card>
            <!-- BOTÃO DE REMOVER -->
            <div class="pr-2">
              <v-tooltip top color="error" dark>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="processOfSave"
                    v-bind="attrs"
                    v-on="on"
                    class="py-5 error"
                    color="white"
                    dark
                    small
                    @click="remove(opcao)"
                  >
                    <v-icon color="white" dark> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </div>
          </div>
        </v-card>
      </draggable>
    </v-card>
    <p class="text-center" v-else>
      Nenhuma opção existente. Clique no botão
      <small>
        <strong> <v-icon small> mdi-plus </v-icon> Adicione uma Opção</strong>
      </small>
      para começar a registrar novas opções.
    </p>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "ListaOpcoesComponent",
  inject: ["$validator"],
  components: {
    draggable,
  },
  props: {
    limitOfOptionsOn: {
      require: false,
      default: true,
    },
    defineScope: {
      require: true,
      default: "",
    },
  },
  methods: {
    addItem() {
      const novaOpcao = {
        descricao: "",
        posicao: null,
      };

      this.pergunta.opcoes.push(novaOpcao);
    },
    remove(option) {
      if (typeof option.uuid != "undefined") {
        this.$store.dispatch("pcoOpcoes/remove", option.uuid).catch((error) => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Houve um erro ao deletar a opção.",
            cor: "red",
          });
        });
      }

      const index = this.pergunta.opcoes.findIndex(
        (el) => el.posicao == option.posicao
      );

      return this.pergunta.opcoes.splice(index, 1);
    },
  },
  computed: {
    pergunta: {
      get() {
        return this.$store.getters["pcoPerguntas/getPergunta"];
      },
    },
    /**
     * @description Cria um array de objetos com a contagem de opções existentes, incluindo a opção sem limite de opção.
     *
     * @author Sandro Souza
     * @modifier_author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    limitOptions() {
      let arrayWithLimitOptions = [];
      arrayWithLimitOptions.push({
        text: "Nenhum limite de opção",
        value: null,
      });

      let count = 1;
      while (count <= this.pergunta.opcoes.length) {
        const newOption = {
          text: String(count),
          value: count,
        };

        arrayWithLimitOptions.push(newOption);

        count++;
      }

      return arrayWithLimitOptions;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    currentPositionInSteps: {
      get() {
        return this.$store.getters["pcoModelos/getCurrentPositionInSteps"];
      },
    },
    processOfSave: {
      get() {
        return this.$store.getters["pcoModelos/getProcessOfSave"];
      },
    },
  },
  watch: {
    /**
     * @description Verifica se o limit de opções está habilitado, caso não estiver ele seta Null para o limite_de_opcoes. Essa função previne ir um limite de opções que não existe
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    limitOfOptionsOn(newValue, oldValue) {
      if (newValue == false) {
        this.pergunta.limite_de_opcoes = null;
      }
    },
    /**
     * @description Verifica se o limit de opções é menor que o limite selecionado, caso for será retornado Null para limite_de_opcoes.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    limitOptions(newValue, oldValue) {
      if (newValue.length - 1 < this.pergunta.limite_de_opcoes) {
        this.pergunta.limite_de_opcoes = null;
      }
    },
  },
};
</script>

<style scoped>
.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.flex-salvar-e-fechar {
  display: flex;
  margin-top: 40px;
  margin-bottom: -36px;
  justify-content: flex-end;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: lightgray !important;
}
.theme--dark.v-icon.v-icon.v-icon--disabled {
  color: darkgray !important;
}
.bg-red {
  background-color: brown !important;
}
</style>
