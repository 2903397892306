import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-space-between"},[_c(VRow,[(_vm.limitOfOptionsOn)?_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VSelect,{ref:"limite_de_opcoes",staticClass:"white",attrs:{"label":"Limite de Opções","messages":"Escolha o limite de opções que poderão ser assinalados. - <strong>Opcional</strong>","placeholder":"Selecione o limite de opções","items":_vm.limitOptions,"item-text":"text","item-value":"value","dense":"","outlined":""},scopedSlots:_vm._u([{key:"message",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.message)}})]}}],null,false,2879978444),model:{value:(_vm.pergunta.limite_de_opcoes),callback:function ($$v) {_vm.$set(_vm.pergunta, "limite_de_opcoes", $$v)},expression:"pergunta.limite_de_opcoes"}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VBtn,{attrs:{"dark":"","color":"primary","disabled":_vm.processOfSave},on:{"click":function($event){return _vm.addItem()}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Adicione uma Opção")])],1)],1)],1),_c(VDivider,{staticClass:"my-3 grey"}),(_vm.pergunta.opcoes.length > 0)?_c(VCard,{staticClass:"pa-1",attrs:{"width":"100%","elevation":"0"}},[_c('draggable',_vm._b({staticClass:"width-max-width-custom",attrs:{"list":_vm.pergunta.opcoes,"handle":"#handle"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.pergunta.opcoes),function(opcao,index){return _c(VCard,{key:opcao.id,staticClass:"mx-3 my-3 d-flex flex-row justify-space-between align-center",attrs:{"elevation":"2","outlined":"","loader-height":"7","min-height":"3em"}},[(
            opcao.descricao != null ||
            opcao.descricao != undefined ||
            opcao.descricao != ''
          )?_c('div',{staticClass:"h-full d-flex flex-row justify-space-between align-center",staticStyle:{"width":"100% !important"}},[_c(VBtn,{staticClass:"grabbable h-full px-0 py-6 white",attrs:{"disabled":_vm.processOfSave,"id":"handle","min-width":"15px","elevation":"0"}},[_c(VIcon,[_vm._v("mdi-drag-vertical")])],1),_c('div',{staticClass:"px-2"},[_c('h4',{staticClass:"text-center"},[_vm._v(" "+_vm._s((opcao.posicao = index + 1))+" ")])]),_c(VCard,{staticClass:"px-2",staticStyle:{"width":"100% !important"},attrs:{"elevation":"0"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],ref:(_vm.defineScope + "." + (opcao.posicao)),refInFor:true,staticClass:"white",attrs:{"label":"Opção","placeholder":"Descreva a opção","required":"","outlined":"","dense":"","hide-details":"","data-vv-as":(_vm.defineScope + "." + (opcao.posicao)),"persistent-hint":"","hint":_vm.errors.first((_vm.defineScope + "." + (opcao.posicao))),"error":_vm.errors.collect((_vm.defineScope + "." + (opcao.posicao))).length
                  ? true
                  : false,"data-vv-scope":_vm.defineScope,"data-vv-validate-on":"blur|change","id":(_vm.defineScope + "." + (opcao.posicao)),"name":(_vm.defineScope + "." + (opcao.posicao))},model:{value:(opcao.descricao),callback:function ($$v) {_vm.$set(opcao, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"opcao.descricao"}})],1),_c('div',{staticClass:"pr-2"},[_c(VTooltip,{attrs:{"top":"","color":"error","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"py-5 error",attrs:{"disabled":_vm.processOfSave,"color":"white","dark":"","small":""},on:{"click":function($event){return _vm.remove(opcao)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white","dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remover")])])],1)],1):_vm._e()])}),1)],1):_c('p',{staticClass:"text-center"},[_vm._v(" Nenhuma opção existente. Clique no botão "),_c('small',[_c('strong',[_c(VIcon,{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Adicione uma Opção")],1)]),_vm._v(" para começar a registrar novas opções. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }