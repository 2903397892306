<template>
  <div>
    <perfect-scrollbar :settings="perfectScrollbarSettings">
      <draggable
        :list="perguntas"
        v-bind="dragOptions"
        handle="#handle"
        class="width-max-width-custom"
        @end="updatedPosition"
      >
        <v-card v-for="(item, index) in perguntas" :key="index">
          <v-card-title class="align-start flex-nowrap">
            <v-avatar
              id="handle"
              size="45"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text"
            >
              <v-icon dense large> mdi-drag-vertical </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-base font-weight-semibold text--primary mb-0">
                {{ index + 1 }} - {{ item.titulo }}
              </p>
              <span class="text-xs" v-html="item.descricao"> </span>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon small class="mt-n2">
              <v-icon
                size="22"
                class="font-weight-semibold warning--text"
                @click="edit(item)"
              >
                mdi-note-edit-outline
              </v-icon>
            </v-btn>
            <v-btn icon small class="mt-n2 mr-2">
              <v-icon
                size="22"
                class="font-weight-semibold error--text"
                @click="remove(item)"
              >
                mdi-delete-outline
              </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </draggable>
    </perfect-scrollbar>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import ListaOpcoesComponent from "@/modules/pco/modelos/components/formularios/components/ListaOpcoesComponent.vue";

export default {
  name: "ComponentPergunta",
  inject: ["$validator"],
  props: {
    perguntas: { require: false, type: Array, default: "" },
  },
  components: {
    draggable,
    PerfectScrollbar,
    ListaOpcoesComponent,
  },
  data: () => ({
    perfectScrollbarSettings: {
      maxScrollbarLength: 10,
      wheelPropagation: false,
    },
    SelectItensTipos: [
      {
        nome: "Texto Aberto",
        tipo: "text",
      },
      {
        nome: "Opção Única",
        tipo: "radio",
      },
      {
        nome: "Múltipla Escolha",
        tipo: "checkbox",
      },
    ],
    enabled: true,
    dragging: false,
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    objetoEdicao: {},
    opcoes: [{ id: "TEste" }, { id: "teste" }],
  }),
  computed: {
    listaPerguntas: {
      get() {
        return this.$store.getters["pcoPerguntas/getAllPerguntas"];
      },
    },
    dialogModalPergunta: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    modalPerguntaSelecionada: {
      get() {
        return this.$store.getters["pesquisas/getModalPerguntaSelecionada"];
      },
      set(value) {
        this.$store.dispatch("pesquisas/setModalPerguntaSelecionada", value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    edit(item) {
      item.isEdit = true;
      this.$store.dispatch("pcoPerguntas/setPergunta", item);
      this.modalPerguntaSelecionada =
        item.categoria == "FE" ? "FE" : item.subcategoria;

      this.dialogModalPergunta = true;
    },
    removerOpcao(item) {
      const indexItem = this.opcoes.indexOf(item);
      this.opcoes.splice(indexItem, 1);
    },
    updatedPosition: function (evt) {
      let items =
        evt.oldIndex > evt.newIndex
          ? this.perguntas.slice(evt.newIndex, evt.oldIndex + 1)
          : this.perguntas.slice(evt.oldIndex, evt.newIndex + 1);
      if (items.length < 2) return;
    },
    adicionarOpcao(item) {
      this.opcoes.push(item);
      this.objetoEdicao.opcoes = this.opcoes;
    },
    salvarOpcoes() {
      this.perguntas.map((el) => {
        if (el.id === this.objetoEdicao.id) {
          el = this.objetoEdicao;
          el.opcoes = this.objetoEdicao.opcoes;
        }
        this.$store.dispatch("snackBarModule/openSnackBar", {
          color: "success",
          timeout: 3000,
          text: "Questão atualizada com sucesso.",
        });
      });
    },
    remove(item) {
      this.$store.dispatch("pcoPerguntas/setPergunta", item);
      this.$store.dispatch("pesquisas/setDialogDeletePergunta", true);
    },
  },
};
</script>

<style lang="scss">
.ps {
  height: 400px;
}
</style>
