import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('perfect-scrollbar',{attrs:{"settings":_vm.perfectScrollbarSettings}},[_c('draggable',_vm._b({staticClass:"width-max-width-custom",attrs:{"list":_vm.perguntas,"handle":"#handle"},on:{"end":_vm.updatedPosition}},'draggable',_vm.dragOptions,false),_vm._l((_vm.perguntas),function(item,index){return _c(VCard,{key:index},[_c(VCardTitle,{staticClass:"align-start flex-nowrap"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"id":"handle","size":"45","rounded":"","color":"primary"}},[_c(VIcon,{attrs:{"dense":"","large":""}},[_vm._v(" mdi-drag-vertical ")])],1),_c('div',{staticClass:"ms-3"},[_c('p',{staticClass:"text-base font-weight-semibold text--primary mb-0"},[_vm._v(" "+_vm._s(index + 1)+" - "+_vm._s(item.titulo)+" ")]),_c('span',{staticClass:"text-xs",domProps:{"innerHTML":_vm._s(item.descricao)}})]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2",attrs:{"icon":"","small":""}},[_c(VIcon,{staticClass:"font-weight-semibold warning--text",attrs:{"size":"22"},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" mdi-note-edit-outline ")])],1),_c(VBtn,{staticClass:"mt-n2 mr-2",attrs:{"icon":"","small":""}},[_c(VIcon,{staticClass:"font-weight-semibold error--text",attrs:{"size":"22"},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-delete-outline ")])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }